import React, { useState } from "react";
import { Link } from "react-router-dom";
import { isMaster } from "../../auth";
import storage from "../../storage";

export default function SideBar(props) {
  const [tema] = useState(JSON.parse(storage.getItem("tema")));

  return (
    <React.Fragment>
      <div
        className="sidebar"
        ref={(node) => {
          if (node) {
            node.style.setProperty("background", tema.sidebar, "important");
          }
        }}
      >
       <div
  className="logo"
  style={{ display: "flex", justifyContent: "center" }}
>
  <a href="#" className="simple-text logo-normal">
    <img
      src="/images/logo.jpg"
      style={{ with: "200px", maxWidth: "200px" }}
    />

</a>
</div>
        {/* <div
          className="logo"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <a href="#" className="simple-text logo-normal">
            <img
              src="/images/logo.jpg"
              style={{ with: "200px", maxWidth: "200px" }}
            />
          </a>
        </div> */}
        <div className="sidebar-wrapper">
          <ul className="nav">
            <li
              className={`${
                props.itemActive === "atendimentos" ? "active" : ""
              }`}
            >
              <Link className="" to="/atendimentos">
                <i
                  className="fa fa-briefcase"
                  ref={(node) => {
                    if (node) {
                      if (props.itemActive === "atendimentos") {
                        node.style.setProperty(
                          "color",
                          tema.textoPrincipal,
                          "important"
                        );
                      } else {
                        node.style.setProperty(
                          "color",
                          tema.textoSidebar,
                          "important"
                        );
                      }
                    }
                  }}
                ></i>
                <p
                  ref={(node) => {
                    if (node) {
                      if (props.itemActive === "atendimentos") {
                        node.style.setProperty(
                          "color",
                          tema.textoPrincipal,
                          "important"
                        );
                      } else {
                        node.style.setProperty(
                          "color",
                          tema.textoSidebar,
                          "important"
                        );
                      }
                    }
                  }}
                >
                  Atendimentos
                </p>
              </Link>
            </li>
            <li
              className={`${props.itemActive === "dashboard" ? "active" : ""}`}
            >
              <Link className="" to="/dashboard">
                <i
                  className="fa fa-tachometer"
                  ref={(node) => {
                    if (node) {
                      if (props.itemActive === "dashboard") {
                        node.style.setProperty(
                          "color",
                          tema.textoPrincipal,
                          "important"
                        );
                      } else {
                        node.style.setProperty(
                          "color",
                          tema.textoSidebar,
                          "important"
                        );
                      }
                    }
                  }}
                ></i>
                <p
                  ref={(node) => {
                    if (node) {
                      if (props.itemActive === "dashboard") {
                        node.style.setProperty(
                          "color",
                          tema.textoPrincipal,
                          "important"
                        );
                      } else {
                        node.style.setProperty(
                          "color",
                          tema.textoSidebar,
                          "important"
                        );
                      }
                    }
                  }}
                >
                  Dashboard
                </p>
              </Link>
            </li>
            {isMaster() && (
              <React.Fragment>
                <li
                  className={`${
                    props.itemActive === "atendentes" ? "active" : ""
                  }`}
                >
                  <Link className="" to="/atendentes">
                    <i
                      className="fa fa-user"
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "atendentes") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    ></i>
                    <p
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "atendentes") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    >
                      Atendentes
                    </p>
                  </Link>
                </li>

                <li
                  className={`${
                    props.itemActive === "consultantes" ? "active" : ""
                  }`}
                >
                  <Link className="" to="/consultantes">
                    <i
                      className="fa fa-group"
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "consultantes") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    ></i>
                    <p
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "consultantes") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    >
                      Pacientes
                    </p>
                  </Link>
                </li>

                <li
                  className={`${
                    props.itemActive === "atividades" ? "active" : ""
                  }`}
                >
                  <Link className="" to="/atividades">
                    <i
                      className="fa fa-user"
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "atividades") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    ></i>
                    <p
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "atividades") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    >
                      Atividades
                    </p>
                  </Link>
                </li>

                <li
                  className={`${
                    props.itemActive === "formasPagamento" ? "active" : ""
                  }`}
                >
                  <Link className="" to="/formas-pagamento">
                    <i
                      className="fa fa-money"
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "formasPagamento") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    ></i>
                    <p
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "formasPagamento") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    >
                      Formas Pagamento
                    </p>
                  </Link>
                </li>

                <li
                  className={`${
                    props.itemActive === "motivosCancelamento" ? "active" : ""
                  }`}
                >
                  <Link className="" to="/motivos-cancelamento">
                    <i
                      className="fa fa-times"
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "motivosCancelamento") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    ></i>
                    <p
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "motivosCancelamento") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    >
                      Motivos Cancelamento
                    </p>
                  </Link>
                </li>

                <li
                  className={`${
                    props.itemActive === "convenios" ? "active" : ""
                  }`}
                >
                  <Link className="" to="/convenios">
                    <i
                      className="fa fa-institution"
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "convenios") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    ></i>
                    <p
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "convenios") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    >
                      Convênios
                    </p>
                  </Link>
                </li>
                <li
                  className={`${
                    props.itemActive === "formularios" ? "active" : ""
                  }`}
                >
                  <Link className="" to="/formularios">
                    <i
                      className="fa fa-edit"
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "formularios") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    ></i>
                    <p
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "formularios") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    >
                      Formulários
                    </p>
                  </Link>
                </li>
                <li
                  className={`${
                    props.itemActive === "templates" ? "active" : ""
                  }`}
                >
                  <Link className="" to="/templates">
                    <i
                      className="fa fa-file-text-o"
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "templates") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    ></i>
                    <p
                      ref={(node) => {
                        if (node) {
                          if (props.itemActive === "templates") {
                            node.style.setProperty(
                              "color",
                              tema.textoPrincipal,
                              "important"
                            );
                          } else {
                            node.style.setProperty(
                              "color",
                              tema.textoSidebar,
                              "important"
                            );
                          }
                        }
                      }}
                    >
                      Templates
                    </p>
                  </Link>
                </li>
              </React.Fragment>
            )}

            <li
              className={`${
                props.itemActive === "relatorios-financeiro" ? "active" : ""
              }`}
            >
              <Link className="" to="/relatorios-financeiro">
                <i
                  className="fa fa-money"
                  ref={(node) => {
                    if (node) {
                      if (props.itemActive === "relatorios-financeiro") {
                        node.style.setProperty(
                          "color",
                          tema.textoPrincipal,
                          "important"
                        );
                      } else {
                        node.style.setProperty(
                          "color",
                          tema.textoSidebar,
                          "important"
                        );
                      }
                    }
                  }}
                ></i>
                <p
                  ref={(node) => {
                    if (node) {
                      if (props.itemActive === "relatorios-financeiro") {
                        node.style.setProperty(
                          "color",
                          tema.textoPrincipal,
                          "important"
                        );
                      } else {
                        node.style.setProperty(
                          "color",
                          tema.textoSidebar,
                          "important"
                        );
                      }
                    }
                  }}
                >
                  Relatório Financeiro
                </p>
              </Link>
            </li>

            <li
              className={`${
                props.itemActive === "configuracoes" ? "active" : ""
              }`}
            >
              <Link className="" to="/configuracoes">
                <i
                  className="fa fa-cogs"
                  ref={(node) => {
                    if (node) {
                      if (props.itemActive === "configuracoes") {
                        node.style.setProperty(
                          "color",
                          tema.textoPrincipal,
                          "important"
                        );
                      } else {
                        node.style.setProperty(
                          "color",
                          tema.textoSidebar,
                          "important"
                        );
                      }
                    }
                  }}
                ></i>
                <p
                  ref={(node) => {
                    if (node) {
                      if (props.itemActive === "configuracoes") {
                        node.style.setProperty(
                          "color",
                          tema.textoPrincipal,
                          "important"
                        );
                      } else {
                        node.style.setProperty(
                          "color",
                          tema.textoSidebar,
                          "important"
                        );
                      }
                    }
                  }}
                >
                  Configurações
                </p>
              </Link>
            </li>

            <li>
              <Link className="" to="/logout">
                <i
                  className="fa fa-power-off"
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty(
                        "color",
                        tema.textoSidebar,
                        "important"
                      );
                    }
                  }}
                ></i>
                <p
                  ref={(node) => {
                    if (node) {
                      node.style.setProperty(
                        "color",
                        tema.textoSidebar,
                        "important"
                      );
                    }
                  }}
                >
                  Sair
                </p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}
